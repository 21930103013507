import {
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  Button,
  TablePagination,
  Table,
  TableContainer,
  Paper
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  CustomCircularProgress,
  CustomSelect,
  NoData,
} from "../../hoc/CustomComponents";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { CustomButton } from "../../hoc/PaletteComponent";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import CreateIcon from "@mui/icons-material/Create";
import TcuHealthParameters from "./TcuHealthParameters";
import EoltLogsReport from "../Vehicles/EoltLogsReport";
import { client2 } from "../tables/client";
import useStyles from "./style";
import RemapImei from "./RemapImei";
const GET_MAPPED_VIN = gql`
  query ($oemId: Int!, $vin: String, $eoltCheck: Boolean) {
    getMappedVins(oemId: $oemId, vin: $vin, eoltCheck: $eoltCheck) {
      code
      message
      rows {
        vin
        imei
        engineNumber
        eoltReportTicket
        eoltCompletedOn
      }
    }
  }
`;

const getFeatureAccess = gql`
  query ($featureId: Int!) {
    getFeatureAccess(featureId: $featureId) {
      id
      userId
      featureId
      createPermission
      readPermission
      updatePermission
      deletePermission
    }
  }
`;

const GET_EOLT_DETAILS_BY_VIN = gql`
  query ($oemId: Int!, $vin: String!) {
    getEoltReportDetails(oemId: $oemId, vin: $vin) {
      code
      message
      details {
        createdOn
        createdByName
        fileName
      }
    }
  }
`;
const GET_DOWNLOAD_URL = gql`
  query ($bucketName: String!, $filename: String!) {
    getPublicDownloadURL(bucketName: $bucketName, filename: $filename)
  }
`;

const UPDATE_IMEI = gql`
  mutation ($oemId: Int!, $vin: String!, $imei: String!) {
    updateImei(oemId: $oemId, vin: $vin, imei: $imei) {
      code
      message
    }
  }
`;

const columns = [
  {
    name: "Sr No.",
    minWidth: 80,
  },
  {
    name: "IMEI",
    minWidth: 150,
  },
  {
    name: "Engine Number",
    minWidth: 150,
  },
  {
    name: "VIN",
    minWidth: 190,
  },
  {
    name: "EOLT Report",
    minWidth: 200,
  },
  {
    name: "Manual EOL",
    minWidth: 150,
  },
  {
    name: "Generate EOLT",
    minWidth: 150,
  },
];
function EOLTDetails(props) {
  const classes = useStyles();
  const [vin, setVin] = useState({
    vin: "",
    imei: "",
  });
  const [allVin, setAllVin] = useState([]);
  const [allDetails, setAllDetails] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [openReport, setopenReport] = useState(false);
  const [readPermission, setReadPermission] = useState(false);
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [selVin, setSelVin] = useState(null);
  const [eoltReport, setEoltReport] = useState(false);
  const [eoltReportDetails, setReportDetails] = useState([[]]);
  const [eoltSelReport, seteolSelRep] = useState("");
  const [edit, setEdit] = useState(false);
  const [editVin, setEditVin] = useState(null);
  const [imei, setImei] = useState(null);
  const [engineNo, setEngineNo] = useState(null);
  let [next, setNext] = useState(0);
  let id;
  if (typeof props.featureId != "undefined") {
    localStorage.setItem("featureId", props.featureId);
    id = props.featureId;
  } else if (parseInt(localStorage.getItem("featureId"))) {
    id = parseInt(localStorage.getItem("featureId"));
  } else {
    id = 19;
  }
  const handleVinSelect = async (e, sel) => {
    if (sel) {
      setVin(sel);
      getEoltDetails({
        variables: {
          oemId: props.oem,
          vin: sel.vin,
        },
      });
      setPage(0);
    } else {
      setVin({
        vin: "",
        imei: "",
      });
      getEoltDetails({
        variables: {
          oemId: props.oem,
        },
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setNext(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setNext(0);
  };

  const generateEOLT = (row) => {
    setopenReport(true);
    setSelVin(row.vin);
  };

  const closeModal = () => {
    setopenReport(false);
    setSelVin(null);
  };

  /**
   * @summary This closes the snackbar
   */
  const handleSnackbarClose = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 7000);
  };
  const closeReport = () => {
    setEoltReport(false);
  };

  const handleEdit = (row) => {
    setEdit(true);
    setEditVin(row.vin);
    setImei(row.imei);
    setEngineNo(row.engineNumber);
  };

  const hanldeImei = (e) => {
    setImei(e.target.value);
  };

  const closeEditModel = (e) => {
    setEdit(false);
  };

  const handleRemap = () => {
    updateIMEI({
      variables: {
        vin: editVin,
        imei: imei,
        oemId: props.oem,
      },
    });
  };
  const handleEoltReport = async (row) => {
    setEoltReport(true);
    getEoltReportsByVIN({
      variables: {
        oemId: props.oem,
        vin: row.vin,
      },
      onCompleted: (res) => {
        if (res?.getEoltReportDetails?.code == 200) {
          setReportDetails(res.getEoltReportDetails.details);
        }
      },
    });
  };
  const handleDownloadReport = async (rep) => {
    console.log(eoltSelReport, "eoltSelReport");
    const response = await client2.query({
      query: GET_DOWNLOAD_URL,
      variables: {
        // bucketName: "sml-files",
        bucketName:  process.env.REACT_APP_AZURE_DEVICEUPLOAD_CONTAINER,
        filename: rep,
      },
      errorPolicy: "all",
    });
    console.log(response, "res");
    if (response?.data?.getPublicDownloadURL) {
      window.open(response.data.getPublicDownloadURL);
    } else {
      props.setMessage("Failed to download");
      props.setNetworkError(true);
      props.setSnackbar(true);
      handleSnackbarClose();
    }
  };
  const [updateIMEI, { data: Response }] = useMutation(UPDATE_IMEI, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleSnackbarClose();
    },
    onCompleted: async (res) => {
      if (res.updateImei.code == 201) {
        props.setMessage("VIN IMEI Mapped Successfully");
      } else {
        props.setMessage(res.updateImei.message);
      }
      props.setNetworkError(true);
      props.setSnackbar(true);
      handleSnackbarClose();
      closeEditModel();
      await getEoltDetails({
        variables: {
          oemId: props.oem,
        },
      });
    },
  });
  const [getEoltReportsByVIN, { loading: logsLoading }] = useLazyQuery(
    GET_EOLT_DETAILS_BY_VIN,
    {
      fetchPolicy: "network-only",
    }
  );

  /**
   * @summary This fetches vin to show in drop down
   */
  const [getDropDownVins] = useLazyQuery(GET_MAPPED_VIN, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Interal Server Error`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }
      if (graphQLErrors) {
        props.setMessage(`Interal Server Error`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }
    },
    onCompleted: (res) => {
      if (res.getMappedVins.code) {
        setAllVin([...res.getMappedVins.rows]);
      } else {
        setAllVin([]);
      }
    },
  });

  /**
   * @summary This fetches all the details for table
   */
  const [getEoltDetails, { data, loading, error }] = useLazyQuery(
    GET_MAPPED_VIN,
    {
      fetchPolicy: "network-only",
      onError: ({ graphQLErrors, networkError }) => {
        if (networkError) {
          props.setMessage(`Interal Server Error`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        }
        if (graphQLErrors) {
          props.setMessage(`Interal Server Error`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        }
      },
      onCompleted: (res) => {
        if (res.getMappedVins.code) {
          setAllDetails([...res.getMappedVins.rows]);
        } else {
          setAllDetails([]);
        }
      },
    }
  );

  const [getAccess, { data: access }] = useLazyQuery(getFeatureAccess, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      console.log("resss----", result);
      if (result && result.getFeatureAccess) {
        result.getFeatureAccess.forEach((element) => {
          if (element.readPermission === 1) {
            setReadPermission(true);
          } else {
            setReadPermission(false);
          }
          if (element.createPermission === 1) {
            setCreatePermission(true);
          } else {
            setCreatePermission(false);
          }
          if (element.updatePermission === 1) {
            setUpdatePermission(true);
          } else {
            setUpdatePermission(false);
          }
          if (element.deletePermission === 1) {
            setDeletePermission(true);
          } else {
            setDeletePermission(false);
          }
        });
      }
    },
  });
  useEffect(() => {
    getAccess({
      variables: {
        featureId: id,
      },
    });
    getDropDownVins({
      variables: {
        oemId: props.oem,
        eoltCheck: true,
      },
    });
    getEoltDetails({
      variables: {
        oemId: props.oem,
      },
    });
    setVin({
      vin: "",
      imei: "",
    });
  }, [props.oem]);
  return (
    <Grid container>
      <TcuHealthParameters
        open={openReport}
        vin={selVin}
        closeModal={closeModal}
        token={props.token}
        oem={props.oem}
        getDropDownVins={getDropDownVins}
        getEoltDetails={getEoltDetails}
      />
      <EoltLogsReport
        open={eoltReport}
        eoltReportDetails={eoltReportDetails}
        handleDownloadReport={handleDownloadReport}
        closeReport={closeReport}
        seteolSelRep={seteolSelRep}
        logsLoading={logsLoading}
      />
      <RemapImei
        open={edit}
        vin={editVin}
        imei={imei}
        engineNo={engineNo}
        handleImei={hanldeImei}
        closeEditModel={closeEditModel}
        handleRemap={handleRemap}
      />
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4">EOLT Report</Typography>
          </Grid>
          <Grid item xs={12} style={{ backgroundColor: "white" }}>
            <Grid container>
              <Grid item xs={5} style={{ padding: "8px" }}>
                <CustomSelect
                  placeholder="Select Vin"
                  value={vin}
                  options={allVin}
                  getOptionLabel={(options) => options.vin}
                  handleSelectChange={handleVinSelect}
                  disableClearable={"false"}
                />
              </Grid>
              <Grid item xs={12} style={{maxHeight:'60vh'}}>
                {loading ? (
                  <CustomCircularProgress />
                ) : allDetails.length > 0 ? (
                  <>
                  <Paper>
                    <TableContainer
                      sx={{
                        overflow: "scroll",
                        maxHeight: "calc(100vh - 270px)"
                      }}
                    >
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                align="center"
                                style={{
                                  minWidth: column.minWidth,
                                  font: "normal normal 600 15px/20px Nunito Sans",
                                  backgroundColor: "#faf8f7",
                                }}
                                className={classes.tableHead}
                              >
                                {column.name}
                              </TableCell>
                            ))}
                            {updatePermission || deletePermission ? (
                              <TableCell
                                align="center"
                                style={{
                                  font: "normal normal 600 15px/20px Nunito Sans",
                                  letterSpacing: "0px",
                                  color: "#212121",
                                  borderRightWidth: 1,
                                  borderColor: "#E8E8E8",
                                  borderTop: "1px solid #E8E8E8",
                                  backgroundColor: "#faf8f7",
                                  minWidth: 140,
                                  padding:'8px'
                                }}
                              >
                                Action
                              </TableCell>
                            ) : null}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {allDetails ? (
                            allDetails
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row, index) => {
                                return (
                                  <TableRow>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {next + (index + 1)}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {row.imei || "NA"}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {row.engineNumber}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {row.vin}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                      style={
                                        row.eoltReportTicket && {
                                          cursor: "pointer",
                                          color: "#57B793",
                                        }
                                      }
                                      onClick={() =>
                                        row.eoltReportTicket &&
                                        handleEoltReport(row)
                                      }
                                    >
                                      {row.eoltReportTicket
                                        ? row.eoltReportTicket
                                        : "NA"}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      <Tooltip
                                        title={
                                          row.eoltCompletedOn
                                            ? "EOLT Completed"
                                            : "EOLT not completed"
                                        }
                                        placement="right-end"
                                      >
                                        {row.eoltCompletedOn ? (
                                          <TaskAltIcon color="success" />
                                        ) : (
                                          <TaskAltIcon
                                            style={{ color: "grey" }}
                                          />
                                        )}
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      <CustomButton
                                        variant="contained"
                                        onClick={() => generateEOLT(row)}
                                        disabled={row.eoltCompletedOn}
                                      >
                                        Generate EOLT
                                      </CustomButton>
                                    </TableCell>
                                    {updatePermission || deletePermission ? (
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        <Grid
                                          container
                                          justifyContent={"center"}
                                          alignContent={"center"}
                                          style={{ height: "5px" }}
                                        >
                                          <Grid item>
                                            <Button
                                              disabled={row.eoltCompletedOn}
                                              onClick={() => handleEdit(row)}
                                            >
                                              <CreateIcon
                                                style={
                                                  !row.eoltCompletedOn
                                                    ? {
                                                      fill: "#2b2924",
                                                    }
                                                    : {
                                                      fill: "grey",
                                                    }
                                                }
                                              />
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      </TableCell>
                                    ) : null}
                                  </TableRow>
                                );
                              })
                          ) : (
                            <NoData />
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[20,50, 100]}
                      component="div"
                      count={allDetails.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Paper>
                  </>
                ) : (
                  <NoData />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withSnackbar(EOLTDetails);
