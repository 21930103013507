import React, { useState, useEffect } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import useStyles from "./styles";
import CreateIcon from "@mui/icons-material/Create";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "throttle-debounce";
import { CustomCircularProgress, NoData } from "../../hoc/CustomComponents";
import { CustomButton } from "../../hoc/PaletteComponent";
import moment from "moment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InvoiceUploadDialog from "./InvoiceUpload";
import FileSaver from "file-saver";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
  Grid,
  Typography,
  TextField,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  Button,
  InputAdornment,
  TablePagination,
  Paper,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { debounceSearchRender } from "mui-datatables";
import axios from "axios";

const getFeatureAccess = gql`
  query($featureId: Int!) {
    getFeatureAccess(featureId: $featureId) {
      id
      userId
      featureId
      createPermission
      readPermission
      updatePermission
      deletePermission
    }
  }
`;

const GET_DOWNLOAD_URL = gql`
  query($filename: String!) {
    getDownloadURL(filename: $filename) {
      code
      remarks
      message
      url
    }
  }
`;

const GET_VEHICLE_CERTIFICATE = gql`
  query($oemId: Int!, $status: Int!, $loginId: Int!,$serviceStatus:String) {
    getOemVehicleData(oemId: $oemId, status: $status, loginId: $loginId ,serviceStatus:$serviceStatus) {
      id
      ticket_number
      vin
      imei
      chassis_no
      vehicleModel
      vehicle_name
      status
      simPlanType
      inprogress_date
      device_model
      device_serialno
      engine_no
      customer_name
      customer_no
      customer_address
      state
      rto_name
      rto_no
      dealer_name
      alt_dealername
      alt_dealerno
      select_project
      customer_altno
      vehicle_oldnew
      vahaan_file
      common_file
      dealer_contact_name
      dealer_contact_number
      year_of_manufacture
      iccid
      payment_by
      payment_status
      state_name
      otp
      otp_datetime
      otp_id
      expiry_on
      msisdn1
      msisdn2
      login_id
      payment_status
      invoice_file
      common_file_date
      vahaan_file_date
      dealerCode
      dealerAddress
      gst
      pan
      paid_amount
    }
  }
`;

const UPLOAD_URL = gql`
mutation ($fileExtension: String!) {
  invoiceFileUpload(fileExtension: $fileExtension) {
      code
      remarks
      message
      filename
      url
    }
  }
`;

const UPDATE_FILE_NAME = gql`
  mutation ($id: Int!, $filename: String!) {
    updateInvoiceFileData(
      id: $id
      filename: $filename
    ) {
      code
      remarks
      message
    }
  }
`;

const EXCEL_DOWNLOAD = gql`
mutation ($oemId: Int!, $downloadFor: String!, $status:Int, $serviceStatus:String, $loginId:Int) {
  excelFileDownload(oemId: $oemId, downloadFor: $downloadFor, status : $status, serviceStatus: $serviceStatus, loginId: $loginId) {
    downloadUrl
  }
}
`;

const colums = [
  {
    label: "Sr.No",
    minWidth: 100,
    align: "center",
  },
  {
    label: "VIN",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Request ID",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Project Name",
    minWidth: 200,
    align: "center",
  },
  {
    label: "In-Progress Date",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Device Model",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Device Serial Number",
    minWidth: 180,
    align: "center",
  },
  {
    label: "Device IMEI Number",
    minWidth: 180,
    align: "center",
  },
  {
    label: "Engine Number",
    minWidth: 150,
    align: "center",
  },
  {
    label: "ICCID Number",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Customer Name",
    minWidth: 350,
    align: "center",
  },
  {
    label: "Customer Number",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Customer OTP number",
    minWidth: 180,
    align: "center",
  },
  {
    label: "Customer Address",
    minWidth: 500,
    align: "center",
  },
  // {
  //   label: "Vehicle Name",
  //   minWidth: 150,
  //   align: "center",
  // },
  {
    label: "Vehicle Model",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Registration State",
    minWidth: 200,
    align: "center",
  },
  {
    label: "RTO Number",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Dealer Code",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Dealer Name",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Dealer Contact Number",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Dealer Address",
    minWidth: 200,
    align: "center",
  },
  {
    label: "GST",
    minWidth: 150,
    align: "center",
  },
  {
    label: "PAN",
    minWidth: 200,
    align: "center",
  },
  // {
  //   label: "Alternate Dealer Name",
  //   minWidth: 180,
  //   align: "center",
  // },
  // {
  //   label: "Alternate Dealer Number",
  //   minWidth: 200,
  //   align: "center",
  // },

  {
    label: "Payment Status",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Amount",
    minWidth: 150,
    align: "center",
  },
  {
    label: "SIM Activation status ",
    minWidth: 180,
    align: "center",
  },
  {
    label: "MSISDN1",
    minWidth: 150,
    align: "center",
  },
  {
    label: "MSISDN2",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Vehicle Type",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Year Of Manufacture",
    minWidth: 180,
    align: "center",
  },
  {
    label: "Fitment Certificate",
    minWidth: 180,
    align: "center",
  },
  {
    label: "Fitment Issued On",
    minWidth: 180,
    align: "center",
  },
  {
    label: "Common Layer certificate",
    minWidth: 200,
    align: "center",
  },
  {
    label: "CLA Issued On",
    minWidth: 180,
    align: "center",
  },
  {
    label: "Invoice Upload",
    minWidth: 200,
    align: "center",
  },
];
function CertificationViewer(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [readPermission, setReadPermission] = useState(false);
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [allData, setAlldata] = useState([]);
  const [certificateData, setCertificateData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filterText, setFilterText] = useState();
  const [fileUpload, setFileUpload] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [uploadFile, setuploadFile] = useState(null);

  let [next, setNext] = useState(0);

  /**
   * @summary handles Search value
   */
  const handleSearchChange = (event) => {
    setFilterText(event.target.value);

    setTimeout(debounceSearchRender(event.target.value), 2000);
  };

  const handleOpen = (id) => {
    console.log(id);
    setRowId(id);
    setUploadOpen(true);
  };

  const handleModelClose = () => {
    setUploadOpen(false);
    setRowId(null);
    setFileUpload(null);
  };

  /**
   * @summary returns the filtered array for search
   */
  const debounceSearchRender = debounce(1000, async (event) => {
    if (event) {
      const filteredRows = allData.filter((row) => {
        const searchText = event.toLowerCase().trim();

        return (
          (row.vin && row.vin.toLowerCase().includes(searchText)) ||
          (row.imei && row.imei.toLowerCase().includes(searchText)) ||
          (row.dealer_name &&
            row.dealer_name.toLowerCase().includes(searchText)) ||
          (row.device_serialno &&
            row.device_serialno.toLowerCase().includes(searchText)) ||
          (row.device_model &&
            row.device_model.toLowerCase().includes(searchText)) ||
          (row.select_project &&
            row.select_project.toLowerCase().includes(searchText)) ||
          (row.ticket_number &&
            row.ticket_number.toLowerCase().includes(searchText)) ||
          (row.simPlanType &&
            row.simPlanType.toLowerCase().includes(searchText)) ||
          (row.customer_name &&
            row.customer_name.toLowerCase().includes(searchText)) ||
          (row.vehicleModel &&
            row.vehicleModel.toLowerCase().includes(searchText)) ||
          (row.vehicle_name &&
            row.vehicle_name.toString().includes(searchText)) ||
          (row.iccid && row.iccid.toLowerCase().includes(searchText))
        );
      });
      setCertificateData(filteredRows);
      //to manage the pagination on search ,setting pagination to intial values
      setRowsPerPage(20);
      setPage(0);
      setNext(0);
    } else {
      await getVehicleCertificate({
        variables: {
          status: 4,
          loginId: -1,
          oemId: props.oem,
          serviceStatus: "AIS140"
        },
      });
    }
  });

  const handleChangePage = (event, newPage) => {
    setFilterText("");
    setCertificateData(allData);
    setNext(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setFilterText("");
    setCertificateData(allData);
    setRowsPerPage(+event.target.value);
    setPage(0);
    setNext(0);
  };

  let id;

  if (typeof props.featureId != "undefined") {
    localStorage.setItem("featureId", props.featureId);
    id = props.featureId;
  } else {
    id = parseInt(localStorage.getItem("featureId"));
  }
  // console.log(id, "idid");
  const [getAccess, { loading, error, data }] = useLazyQuery(getFeatureAccess, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      console.log("resss----", result);
      if (result && result.getFeatureAccess) {
        result.getFeatureAccess.forEach((element) => {
          console.log(result, "result");
          if (element.readPermission === 1) {
            setReadPermission(true);
          } else {
            setReadPermission(false);
          }
          if (element.createPermission === 1) {
            setCreatePermission(true);
          } else {
            setCreatePermission(false);
          }
          if (element.updatePermission === 1) {
            setUpdatePermission(true);
          } else {
            setUpdatePermission(false);
          }
          if (element.deletePermission === 1) {
            setDeletePermission(true);
          } else {
            setDeletePermission(false);
          }
        });
      }
    },
  });

  // console.log(page, rowsPerPage, "page rowperpage");
  const [
    getVehicleCertificate,
    {
      loading: certficateLoading,
      error: certficateError,
      data: vehicleCertificateData,
    },
  ] = useLazyQuery(GET_VEHICLE_CERTIFICATE, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      // console.log(result.getOemVehicleData, "dataa");
      setAlldata(result.getOemVehicleData);
      setCertificateData(result.getOemVehicleData);
      setTotalCount(result.getOemVehicleData.length);
    },
  });

  /**
   * @summary This closes the snackbar
   */
  const handleSnackbarClose = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 7000);
  };

  const [
    onFileDownload,
    { loading: downloadLoading, error: downloadError, data: downloadData },
  ] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "network-only",

  });

  const onDownload = (filename) => {
    onFileDownload({
      variables: {
        filename: filename,
      },
      onCompleted: async (result) => {
        if (result.getDownloadURL && result.getDownloadURL.url) {
          const url = result.getDownloadURL.url;

          const res = await axios({
            url: url,
            method: "GET",
            headers: { Accept: "application/vnd.ms-excel" },
            responseType: "blob",
          });

          FileSaver.saveAs(new Blob([res.data]), filename);
        }
      },
    });
  };

  useEffect(() => {
    getAccess({
      variables: {
        featureId: id,
      },
    });
    getVehicleCertificate({
      variables: {
        status: 4,
        loginId: -1,
        oemId: props.oem,
        serviceStatus: "AIS140"
      },
    });
  }, [props.oem]);

  /**
   * @summary Fetches a url for uploading a file and uploads the file
   */
  const onUpload = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    // setIsBulkUploadOpen(false);
    console.log("file----", file);
    console.log("validdiiitt---", validity);
    if (file) {
      //setUpload(true);
      //setProgressDialog(true);
      setuploadFile(file);
      props.setMessage("");

      props.setExcelError("");
      props.closeDialog(false);
      props.setExcelData(null);
      props.setUpload(false);
      if (validity.valid) {
        const doubleExt = file.name.match(/\.(.+)$/)[1];
        if (doubleExt.indexOf('.') !== -1) {
          // alert("Please select correct file format");
          setFileUpload(null);
          props.setMessage("Please select correct file format!");
          props.setSnackbar(true);
          handleSnackbarClose();
        }
        else {

          const fileExtension = file.name.substring(
            file.name.lastIndexOf(".") + 1
          );
          props.setMessage(`Uploading....`);
          props.setSnackbar(true);
          await uploadURL({
            variables: {
              fileExtension,
            },
          });
        }
      }
    } else {
      console.log("file----", file);
      alert("No file selected");
    }
  };

  /**
   * @summary api  call to upload vahaan file
   */
  const [uploadURL] = useMutation(UPLOAD_URL, {
    fetchPolicy: "no-cache",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleSnackbarClose();
    },
    onCompleted: async (response) => {
      // console.log(response, "responseVahaan");

      // console.log("invoiceFileUpload::",response.invoiceFileUpload);
      // console.log("response.invoiceFileUpload.message::",response.invoiceFileUpload.message)
      if (response && response.invoiceFileUpload.url) {
        await axios.put(response.invoiceFileUpload.url, uploadFile, {
          headers: {
            'x-ms-blob-type': 'BlockBlob',
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            let percentage = Math.round((100 * data.loaded) / data.total);

            props.setSnackbar(false);
          },
        });

        setFileUpload(response.invoiceFileUpload.filename);
        // console.log(response.invoiceFileUpload.url)

      } else if (response && response.invoiceFileUpload.code == 400) {
        setFileUpload(null);
        props.setMessage(response.invoiceFileUpload.message);
        props.setSnackbar(true);
        handleSnackbarClose();
      } else {
        setFileUpload(null);
        props.setMessage("Something went wrong,Please Try again!");
        props.setSnackbar(true);
        handleSnackbarClose();
      }
    },
  });

  const uploadCertificate = () => {
    console.log(rowId, fileUpload, "variables");
    updateInvoiceFileName({
      variables: {
        id: rowId,
        filename: fileUpload,
      },
    });
  };

  /**
   * @summary api call to set kyc Details
   */
  const [
    updateInvoiceFileName,
    { error: createError, loading: createloading, data: createData },
  ] = useMutation(UPDATE_FILE_NAME, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleModelClose();
      handleSnackbarClose();
    },
    onCompleted: async (result) => {
      console.log(result, "result");
      if (result.updateInvoiceFileData.code == 200) {
        props.setMessage(result.updateInvoiceFileData.message);
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleModelClose();
      } else {
        if (result.updateInvoiceFileData.code != 200) {
          props.setMessage(result.updateInvoiceFileData.message);
          props.setNetworkError(true);
          props.setSnackbar(true);
          handleModelClose();
        }
      }
      handleSnackbarClose();
      await getVehicleCertificate({
        variables: { status: 4, loginId: -1, oemId: props.oem, serviceStatus: "AIS140" },
      });
    },
  });

  const [getDownloadUrl, { data: url, loading: downloading }] = useMutation(
    EXCEL_DOWNLOAD,
    {
      fetchPolicy: "network-only",
      onError: ({ graphQLErrors, networkError }) => {
        if (networkError) {
          props.setMessage(`Message: ${networkError}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        }
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            props.setMessage(`Message: ${message}`);
            props.setNetworkError(true);
            props.setSnackbar(true);
          });
        }
      },
      onCompleted: async (result) => {
        if (result.excelFileDownload.downloadUrl) {
          const res = await axios({
            url: result.excelFileDownload.downloadUrl,
            method: "GET",
            headers: { Accept: "application/vnd.ms-excel" },
            responseType: "blob", // important
          });

          FileSaver.saveAs(new Blob([res.data]), "Certification_Viewer.xlsx");
        } else {
          props.setMessage("Something went wrong,Please Try again!");
          props.setSnackbar(true);
          handleClose();
        }
      },
    }
  );

  const DownloadExcel = () => {
    getDownloadUrl({
      variables: {
        oemId: props.oem,
        downloadFor: "CERTIFICATE_VIEWER",
        status: 4,
        loginId: -1,
        serviceStatus:"AIS140",
      },
    });
  };

  /**
   * @summary closes the snackbar
   */
  const handleClose = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 7000);
  };

  return (
    <>
      <InvoiceUploadDialog
        isModelOpen={uploadOpen}
        handleModelClose={handleModelClose}
        certificateId={rowId}
        onUpload={onUpload}
        // onUploadCommon={onUploadCommon}
        onSubmit={uploadCertificate}
        snackbarOpen={props.open}
        // fileType={fileType}
        fileUpload={fileUpload}
      />
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item>
              <Typography variant="h4">Certificate Viewer</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ paddingTop: '0px', paddingLeft: '32px' }}
            >
              <Grid
                container
                style={{
                  backgroundColor: "#FFFFFF",
                  // paddingTop: "20px",
                  // marginLeft: "-10px !important",
                }}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    paddingBottom: "8px",
                    paddingTop: "8px",
                    paddingLeft: "8px",
                  }}
                >
                  <TextField
                    placeholder="Search Number"
                    variant="outlined"
                    // multiline
                    value={filterText}
                    onChange={(e) => handleSearchChange(e)}
                    style={{ width: "80%", backgroundColor: "#F3F3F3 " }}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ paddingLeft: "3px" }} />
                        </InputAdornment>
                      ),
                    }}
                    className={classes.textfield}
                  />
                  {downloading ? (
                    <CircularProgress style={{ color: "#57B793" }} />
                  ) : (
                    <IconButton
                      title="Download Excel"
                      aria-label="Download Excel"
                      //
                      onClick={DownloadExcel}
                      disabled={totalCount == 0}
                    >
                      <CloudDownloadIcon
                        style={{ fill: "#57B793", fontSize: 30 }}
                      />
                    </IconButton>
                  )}
                </Grid>

                <Grid item xs={12} style={{ maxHeight: '60vh' }}>
                  {certficateLoading ? (
                    <CustomCircularProgress />
                  ) : certificateData && certificateData.length > 0 ? (
                    <Paper>
                      <TableContainer className={classes.container} style={{ maxHeight: "calc(100vh - 300px)" }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {colums.map((column) => (
                                <>
                                  <TableCell
                                    align={column.align}
                                    style={{
                                      minWidth: column.minWidth,
                                      font:
                                        "normal normal 600 14px/18px Nunito Sans",
                                      letterSpacing: "0px",
                                      color: "#212121",
                                      borderRightWidth: 1,
                                      borderColor: "#E8E8E8",
                                      borderTop: "1px solid #E8E8E8",
                                      padding: '8px'
                                    }}
                                  >
                                    {column.label}
                                  </TableCell>
                                </>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {certificateData ? (
                              certificateData
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                  let inprogressDate = row.inprogress_date
                                    ? moment
                                      .unix(row.inprogress_date)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                    : null;

                                  let otpDateTime = row.otp_datetime
                                    ? moment
                                      .unix(row.otp_datetime)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                    : null;

                                  let claIssuedOn = row.common_file_date
                                    ? moment
                                      .unix(row.common_file_date)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                    : null;
                                  let vahIssuedOn = row.vahaan_file_date
                                    ? moment
                                      .unix(row.vahaan_file_date)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                    : null;
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                    >
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {next + (index + 1)}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.vin}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.ticket_number}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.select_project}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {inprogressDate}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.device_model}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.device_serialno}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.imei}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.engine_no}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.iccid}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.customer_name}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.customer_no}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.customer_altno}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.customer_address}
                                      </TableCell>
                                      {/* <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.vehicle_name}
                                      </TableCell> */}
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.vehicleModel}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.state_name}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.rto_no}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.dealerCode ? row.dealerCode : 'N/A'}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.dealer_name}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.dealer_contact_number}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.dealerAddress ? row.dealerAddress : 'N/A'}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.gst ? row.gst : 'N/A'}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.pan ? row.pan : 'N/A'}
                                      </TableCell>
                                      {/* <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.alt_dealername}
                                      </TableCell> */}
                                      {/* <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.alt_dealerno}
                                      </TableCell> */}

                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.payment_status
                                          ? row.payment_status
                                          : "N/A"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.paid_amount ? row.paid_amount : 'N/A'}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.simPlanType
                                          ? row.simPlanType
                                          : "N/A"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.msisdn1 ? row.msisdn1 : "N/A"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.msisdn2 === 'null' ? "N/A" : row.msisdn2}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.vehicle_oldnew}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.year_of_manufacture}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.vahaan_file ? (
                                          <CustomButton
                                            style={{ fontSize: "8px" }}
                                            onClick={() =>
                                              onDownload(row.vahaan_file)
                                            }
                                            variant="contained"
                                          >
                                            Download
                                          </CustomButton>
                                        ) : (
                                          <CustomButton
                                            style={{ fontSize: "8px" }}
                                            variant="contained"
                                          >
                                            N/A
                                          </CustomButton>
                                        )}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {vahIssuedOn ? vahIssuedOn : 'N/A'}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.common_file ? (
                                          <CustomButton
                                            style={{ fontSize: "8px" }}
                                            onClick={() =>
                                              onDownload(row.common_file)
                                            }
                                            variant="contained"
                                          >
                                            Download
                                          </CustomButton>
                                        ) : (
                                          <CustomButton
                                            style={{ fontSize: "8px" }}
                                            variant="contained"
                                          >
                                            N/A
                                          </CustomButton>
                                        )}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {claIssuedOn ? claIssuedOn : 'N/A'}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        <CustomButton
                                          variant="contained"
                                          onClick={() =>
                                            handleOpen(row.id)
                                          }
                                          disabled={
                                            row.simPlanType !=
                                            "COMMERCIALLY_ACTIVATED" ||
                                            row.invoice_file !== null
                                          }
                                        >
                                          <CloudUploadIcon />
                                        </CustomButton></TableCell>
                                    </TableRow>
                                  );
                                })
                            ) : (
                              <>No Records found!</>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[20, 50, 100]}
                        component="div"
                        count={totalCount || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <NoData />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default withSnackbar(CertificationViewer);
