import { Button, Dialog, Grid, Typography, CircularProgress } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CustomSelect, CustomTextField } from "../../hoc/CustomComponents";
import { ColorButton, CustomButton } from "../../hoc/PaletteComponent";
export default function vinNoChange({
    open,
    closeVinChangeModel,
    vin,
    imei,
    iccid,
    handleVinChangeSumbit,
    setNewVin,
    newVin,
    changeVinNumberLoading,
    disableCloseButton,
    setImei,
    setIccid,
}) {

    /**
   * @summary This sets the value of new vin
   */
    const handleNewVinChange = (e) => {
        setNewVin(e);
    };

    /**
   * @summary This sets the value of IMEI
   */
    const handleImeiChange = (e) => {
        setImei(e);
    };

    /**
   * @summary This sets the value of ICCID
   */
    const handleIccidChange = (e) => {
        setIccid(e);
    };

    return (
        <Dialog
            open={open}
            maxWidth={"md"}
            fullWidth={true}
            aria-labelledby="max-width-dialog-title"
            disableEscapeKeyDown={true}
        >
            <Grid container>
                <Grid item xs={12} style={{ padding: "25px" }}>
                    <Grid container>
                        <Grid item xs={9}>
                            <Typography variant="h5">Vin Number Change</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Button style={{ float: "right" }} onClick={closeVinChangeModel}>
                                <CloseIcon
                                    style={{
                                        fill: "#2b2924",
                                    }}
                                />
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <CustomTextField
                                        value={vin}
                                        label="Vin*"
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomTextField
                                        // value={}
                                        label="New Vin*"
                                        placeholder="Enter New Vin"
                                        onChange={(e) => handleNewVinChange(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} style={{ paddingTop: "16px" }}>
                                <Grid item xs={6}>
                                    <CustomTextField
                                        value={imei}
                                        label="Imei*"
                                        placeholder="Enter Imei"
                                        // disabled={true}
                                        onChange={(e) => handleImeiChange(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomTextField
                                        value={iccid}
                                        label="Iccid*"
                                        placeholder="Enter Iccid"
                                        // disabled={true}
                                        onChange={(e) => handleIccidChange(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                container
                                display={"flex"}
                                justifyContent="flex-end"
                                spacing={1}
                                style={{ paddingTop: "16px" }}
                            >
                                <Grid item xs={2}>
                                    <ColorButton 
                                        onClick={closeVinChangeModel}
                                        disabled={
                                            disableCloseButton == true
                                          }
                                    >
                                        <Typography>Cancel</Typography>
                                    </ColorButton>
                                </Grid>
                                <Grid item xs={2}>
                                    {changeVinNumberLoading ? (
                                    <CircularProgress style={{ color: "#57B793" }} />
                                    ) : (
                                    <ColorButton 
                                        onClick={handleVinChangeSumbit} 
                                        disabled={
                                            newVin == null ||
                                            newVin == "" ||
                                            imei == null ||
                                            imei == "" ||
                                            iccid == null ||
                                            iccid == ""
                                        }
                                    >
                                    <Typography>Submit</Typography>
                                    </ColorButton>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
}
